<template>
  <div class="page-container">
    <md-app class="full-height">
      <md-app-toolbar class="md-primary">
        <span class="md-title">AMMCO-AI</span>
      </md-app-toolbar>
      <md-app-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-15">
            <md-content class="md-scrollbar pictures-col-style" v-bind:style="picturesColumnStyle">
              <div class="upload-file">
                <label>Upload file</label>
                <input type="file" accept="image/*"  v-on:change="onFileUpload($event)">
              </div>
              <img v-for="(pic, index) in pictures" :key="index" alt="" :src="pic.src" class="asset-img" v-on:click="imageClicked($event, pic.src)">
            </md-content>
          </div>
          <div class="md-layout-item">
            <div class="md-layout md-gutter">
              <div class="md-layout-item">
                <img ref="selectedImage" alt="" v-bind:src="selectedImage.src" class="asset-selected-img">
              </div>
              <div class="md-layout-item predictions">
                <div v-for="(p, index) in prediction" :key="index">- {{ p.className }}: {{ p.score }}%</div>
              </div>
            </div>
          </div>
        </div>
      </md-app-content> 
    </md-app>
  </div>
</template>

<script>
import * as tf from '@tensorflow/tfjs';

export default {
  data: function () {
    return {
      uploadedPhoto: null,
      picturesColumnStyle: {},
      pictures: [
        { src: require("../assets/03.jpg") },
        { src: require("../assets/01.jpg") },
        { src: require("../assets/02.jpg") },
        { src: require("../assets/04.jpg") },
        { src: require("../assets/05.jpg") },
        { src: require("../assets/06.jpg") },
        { src: require("../assets/07.jpg") },
        { src: require("../assets/08.jpg") },
        { src: require("../assets/09.jpg") },
        { src: require("../assets/10.jpg") },
        { src: require("../assets/11.jpg") },
        { src: require("../assets/12.jpg") },
        { src: require("../assets/13.jpg") },
        { src: require("../assets/14.jpg") },
        { src: require("../assets/15.jpg") },
        { src: require("../assets/16.jpg") },
        { src: require("../assets/17.jpg") },
        { src: require("../assets/18.jpg") },
        { src: require("../assets/19.jpg") },
        { src: require("../assets/20.jpg") },
        { src: require("../assets/21.jpg")  }
      ],
      categories: ['AUTRE', 'PANNEAU ELECTRIQUE', 'VENTILATEUR', 'ECLAIRAGE DE SECOURS', 'POMPE',
       'VENTILO-CONVECTEUR', 'CLIMATISEUR', 'RESERVOIR', 'TRANSFORMATEUR',
       'AEROTHERME', 'CHAUFFE-EAU', 'PORTE', 'SERPENTIN',
       'TROUSSE PREMIERS SOINS', 'COMPTEUR', 'DETECTEUR DE GAZ', 'FONTAINE',
       'SYSTEME DETECTION INTRUSION', 'DEFIBRILLATEUR CARDIAQUE',
       'RESEAU AVERTISSEUR', 'CONDENSEUR A AIR', 'GRILLE', 'ENTREE GAZ ',
       'MOBILIER FIXE', 'RACCORDS POMPIERS', 'DISPOSITIF ANTI-REFOULEMENT',
       'CHAUFFAGE RADIANT', 'ARMOIRE INCENDIE', 'FILTRE', 'ECHANGEUR',
       'PLANCHER', 'INTERRUPTEUR', 'RESEAU GICLEUR', 'STATIONNEMENT',
       'VARIATEUR DE VITESSE', 'CHAUDIERE', 'TRAITEMENT EAU',
       'MAKE UP AIR UNIT', 'RIDEAU', 'TOITURE', 'GROUPE ELECTROGENE', 'CTA',
       'MAT PORTE-DRAPEAU', 'BORNE FONTAINE'],
      model: null,
      selectedImage: {src: ''},
      prediction: []
    }
  },
  methods: {
      onFileUpload(event) {
        let reader = new FileReader();
        const self = this;
        reader.onload = function() {
            self.$set(self.selectedImage, 'src', reader.result);
            self.doInference(self.$refs.selectedImage);
        }
        reader.readAsDataURL(event.target.files[0]);
      },
      imageClicked(event, src) {
        this.$set(this.selectedImage, 'src', src); 
        this.doInference(event.target);
      },
      doInference(img) {
        let t = tf.browser.fromPixels(img);

        // normalize image
        t = t.div(tf.scalar(256));

        // resize image
        t = tf.image.resizeBilinear(t, [128, 128]);
        
        t = tf.expandDims(t, 0);
        let pred = this.model.predict(t);

        const {values, indices} = tf.topk(pred, 4);

        const probs = values.dataSync();
        let result = []
        indices.dataSync().forEach((cid, idx) => result.push({className: this.categories[cid], score: Math.round(probs[idx] * 100)}));

        this.prediction = result.filter(value => value.score > 0);
      },
      matchHeight() {
          var heightString = (window.innerHeight - 150) + 'px';
          this.$set(this.picturesColumnStyle, 'height', heightString); 
      },
      async loadModel() {
        const model = await tf.loadLayersModel('/model/model.json');
        this.model = model;
        console.log("AI Model Loaded");
      }
  },
  mounted() {
      this.matchHeight();
      this.loadModel();
  }
}
</script>

<style lang="scss" scoped>
  .page-container, .full-height {
    height: 100%;
  }

  .asset-img {
    width: 256px;
    margin-bottom: 5px;
  }

  .pictures-col-style {
    height: 400px;
    padding: 10px;
    overflow: auto;
    overflow-x: hidden;
  }

  .asset-selected-img {
    width: 400px;
    margin: 20px;
  }

  .predictions {
    margin: 20px;
  }

  .upload-file {
    margin-bottom: 15px;
  }
</style>
